import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LABluePaper, LAGreyPaper, LAMaroonPaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isLoading, isNotLoaded, isSucceeded, Server, STATUS_ENUM } from "../../../../../redux/server";
import { ById, SimosAPIResponse } from "../../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, deleteText, NotApplicable, undefinedFunction, userName, yesOrNoOptions } from "../../../../shared/constExports";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { BLUE_COLOR, GREEN_COLOR, LIGHT_GREY_COLOR, MEDIA_QUERY_PHONE, MEDIA_QUERY_PHONE_MD, ORANGE_COLOR, RED_COLOR, WHITE_COLOR } from "../../../../shared/theme";
import { ROUTE } from "../../../../routes";
import PageSpacing from "../../../../shared/pageSpacing";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import queryString from "query-string";
import { SimosSubHeader, SimosSubHeaderMobile } from "../../../../header/simosHeader";
import { IProductOrder, ISIMOSOrders } from "../../../../../redux/propsense/simos/getOrders/getOrdersConstaints";
import { ISimosCustomers, ISimosCustomersRequest } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersConstants";
import { getSimosCustomers } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersAccessor";
import { getSimosCustomersLoadAction } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersActions";
import { getSimosTransportCompanies } from "../../../../../redux/propsense/simos/transportCompanies/getSimosTransportCompanies/getSimosTransportCompaniesAccessor";
import { getSimosTransportCompaniesLoadAction } from "../../../../../redux/propsense/simos/transportCompanies/getSimosTransportCompanies/getSimosTransportCompaniesActions";
import { ISimosTransportCompanies, ISimosTransportCompaniesRequest } from "../../../../../redux/propsense/simos/transportCompanies/getSimosTransportCompanies/getSimosTransportCompaniesConstants";
import { ISimosProducts, ISimosProductsRequest } from "../../../../../redux/propsense/simos/simosProducts/getSimosProducts/getSimosProductsConstants";
import { getSimosProductsLoadAction } from "../../../../../redux/propsense/simos/simosProducts/getSimosProducts/getSimosProductsActions";
import { getSimosProducts } from "../../../../../redux/propsense/simos/simosProducts/getSimosProducts/getSimosProductsAccessor";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue } from "../../../../shared/fieldValidator";
import LAAutoComplete from "../../../../shared/autoComplete";
import { LADateTimeMUIPicker } from "../../../../shared/dateTimePickerMUI";
import LATextField from "../../../../shared/textField";
import { LACheckBox } from "../../../../shared/checkBox";
import LATextArea from "../../../../shared/textArea";
import { IIdName } from "../../../../../utils/sharedTypes";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { AddIcon, ArrowLeftIcon, ContactIcon, DeleteIcon, EmailIcon, FaxIcon, PhoneIcon } from "../../../../shared/icons";
import { LAButton, LAIconButton, LASaveAndCancelButton, LASecondaryButton } from "../../../../shared/buttons";
import { LAPopover } from "../../../../shared/popOver";
import AddUpdateSimosTransportCompany from "../TransportCompanies/addUpdateTransportCompany";
import LAErrorBox from "../../../../shared/errorBox";
import { IAddOrderRequest } from "../../../../../redux/propsense/simos/addOrder/addOrderConstaints";
import { updateOrderStatus } from "../../../../../redux/propsense/simos/updateOrder/updateOrderAccessor";
import { addOrderStatus } from "../../../../../redux/propsense/simos/addOrder/addOrderAccessor";
import { addOrderLoadAction } from "../../../../../redux/propsense/simos/addOrder/addOrderActions";
import AddUpdateSimosCustomer from "../Customers/addUpdateCustomer";
import AddUpdateSimosCustomerContact from "../CustomerContacts/addUpdateCustomerContact";
import { updateOrderLoadAction } from "../../../../../redux/propsense/simos/updateOrder/updateOrderActions";
import { ISimosCustomerContacts, ISimosCustomerContactsRequest } from "../../../../../redux/propsense/simos/simosCustomerContacts/getSimosCustomerContacts/getSimosCustomerContactsConstants";
import { getSimosCustomerContacts } from "../../../../../redux/propsense/simos/simosCustomerContacts/getSimosCustomerContacts/getSimosCustomerContactsAccessor";
import { getSimosCustomerContactsLoadAction } from "../../../../../redux/propsense/simos/simosCustomerContacts/getSimosCustomerContacts/getSimosCustomerContactsActions";
import { DevExtremePopupLookup } from "../../../../shared/devExtremeLookup";
import RequestStatus from "../../../../shared/requestStatusSnackbar";
import { IGetProductsByIdRequest, ISIMOSProductsById } from "../../../../../redux/propsense/simos/getProductsById/getProductsByIdConstaints";
import { getProductsById } from "../../../../../redux/propsense/simos/getProductsById/getProductsByIdAccessor";
import { getProductsByIdLoadAction } from "../../../../../redux/propsense/simos/getProductsById/getProductsByIdActions";
import { getOrderContactLoadAction } from "../../../../../redux/propsense/simos/getOrderContact/getOrderContactActions";
import { IGetOrderContactRequest, ISIMOSOrderContact } from "../../../../../redux/propsense/simos/getOrderContact/getOrderContactConstaints";
import { getOrderContactStatus } from "../../../../../redux/propsense/simos/getOrderContact/getOrderContactAccessor";
import { IGetOrderByIdRequest } from "../../../../../redux/propsense/simos/getOrderById/getOrderByIdConstaints";
import { getOrderByIdLoadAction } from "../../../../../redux/propsense/simos/getOrderById/getOrderByIdActions";
import { getOrderByIdStatus } from "../../../../../redux/propsense/simos/getOrderById/getOrderByIdAccessor";
import { IUpdateOrder, IUpdateOrderRequest, IUpdateProductOrder } from "../../../../../redux/propsense/simos/updateOrder/updateOrderConstaints";
import { getSimosTrucksStatus } from "../../../../../redux/propsense/simos/getSimosTrucks/getSimosTrucksAccessor";
import { getSimosTrucksLoadAction } from "../../../../../redux/propsense/simos/getSimosTrucks/getSimosTrucksActions";
import { IGetSimosTrucksRequest, ISimosTrucks } from "../../../../../redux/propsense/simos/getSimosTrucks/getSimosTrucksConstaints";
import { ProductVersionHistory } from "./productVersionHistory";
import { IGetSIMOSOrderProductVersionHistoryRequest, ISIMOSOrderProductVersionHistory } from "../../../../../redux/propsense/simos/getSIMOSOrderProductVersionHistory/getSIMOSOrderProductVersionHistoryConstaints";
import { getSIMOSOrderProductVersionHistory } from "../../../../../redux/propsense/simos/getSIMOSOrderProductVersionHistory/getSIMOSOrderProductVersionHistoryAccessor";
import { getSIMOSOrderProductVersionHistoryLoadAction } from "../../../../../redux/propsense/simos/getSIMOSOrderProductVersionHistory/getSIMOSOrderProductVersionHistoryActions";
import { getSalesOrderNumbersByCustomerID } from "../../../../../redux/propsense/simos/getSalesOrderNumbersByCustomerID/getSalesOrderNumbersByCustomerIDAccessor";
import { getSalesOrderNumbersByCustomerIDLoadAction } from "../../../../../redux/propsense/simos/getSalesOrderNumbersByCustomerID/getSalesOrderNumbersByCustomerIDActions";
import { IGetSalesOrderNumbersByCustomerIDRequest, ISalesOrderNumbersByCustomerID } from "../../../../../redux/propsense/simos/getSalesOrderNumbersByCustomerID/getSalesOrderNumbersByCustomerIDConstaints";
import { AddSalesOrderNumberPopup } from "./addSalesOrderNumberPopup";
import { IAddSalesOrderNumberRequest } from "../../../../../redux/propsense/simos/addSalesOrderNumber/addSalesOrderNumberConstaints";
import { addSalesOrderNumberStatus } from "../../../../../redux/propsense/simos/addSalesOrderNumber/addSalesOrderNumberAccessor";
import { addSalesOrderNumberLoadAction } from "../../../../../redux/propsense/simos/addSalesOrderNumber/addSalesOrderNumberActions";
import { MdOutlinePhoneIphone } from "react-icons/md";


const requiredFields = ["customer", "address", "selectedContact", "transport_Company", "truck_Required"];

export const truckSizeList: IIdName<number>[] = [
    { id: 0, name: "Single Bulk" },
    { id: 1, name: "Single Deck" },
    { id: 2, name: "BTrain Bulk" },
    { id: 3, name: "BTrain Deck" },
    { id: 4, name: "Super BTrain Bulk" },
    { id: 5, name: "Super BTrain Deck" },
    { id: 6, name: "Truck and Pup" },
    { id: 7, name: "Tandem" },
    { id: 8, name: "End Dump" },
    { id: 9, name: "Truck and Wagon" },
    { id: 10, name: "Triaxle Deck" },
    { id: 11, name: "Triaxle Bulk" },
    { id: 12, name: "Customer Pickup" }
];

interface ISILEdOrderStoreProps {
    getToken: Server<SimosAPIResponse<IToken>>;
    getSILEdOrder: Server<SimosAPIResponse<ISIMOSOrders>>;
    getSimosProductsList: Server<SimosAPIResponse<ISimosProducts[]>>;
    getProducts: Server<SimosAPIResponse<ISIMOSProductsById[]>>;
    getSimosCustomersList: Server<SimosAPIResponse<ISimosCustomers[]>>;
    getSimosCustomerContacts: Server<SimosAPIResponse<ISimosCustomerContacts[]>>;
    getSimosTransportCompanyList: Server<SimosAPIResponse<ISimosTransportCompanies[]>>;
    getOrderContact: Server<SimosAPIResponse<ISIMOSOrderContact[]>>;
    getSimosTrucks: Server<SimosAPIResponse<ISimosTrucks[]>>;
    addOrder: Server<string>;
    updateOrder: Server<string>;
    getSimosOrderProductVersionHistory: Server<SimosAPIResponse<ISIMOSOrderProductVersionHistory[]>>;
    getSalesOrderNumbersByCustomerID: Server<SimosAPIResponse<ISalesOrderNumbersByCustomerID[]>>;
    addSalesOrderNumber: Server<string>;
};

interface ISILEdOrderDispatchProps {
    getSILEdOrderRequest: (data: IGetOrderByIdRequest) => unknown;
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getProductsRequest: (data: IGetProductsByIdRequest) => unknown;
    getSimosCustomersRequest: (data: ISimosCustomersRequest) => unknown;
    getSimosProductsListRequest: (data: ISimosProductsRequest) => unknown;
    getSimosCustomerContactsRequest: (data: ISimosCustomerContactsRequest) => unknown;
    getSimosTransportCompanyRequest: (data: ISimosTransportCompaniesRequest) => unknown;
    addOrderRequest: (data: IAddOrderRequest) => unknown;
    updateOrderRequest: (data: IUpdateOrderRequest) => unknown;
    getOrderContactRequest: (data: IGetOrderContactRequest) => unknown;
    getSimosTrucksRequest: (data: IGetSimosTrucksRequest) => unknown;
    getSimosOrderProductVersionHistoryRequest: (data: IGetSIMOSOrderProductVersionHistoryRequest) => unknown;
    getSalesOrderNumbersByCustomerIDRequest: (data: IGetSalesOrderNumbersByCustomerIDRequest) => unknown;
    addSalesOrderNumberRequest: (data: IAddSalesOrderNumberRequest) => unknown;
};


interface ISILEdOrderOwnProps {

};

interface ISILEdOrderState {
    collect: boolean;
    order: ISIMOSOrders;
    addAddress: boolean;
    addSalesOrder: boolean;
    addContact: boolean;
    addCustomer: boolean;
    addTransport: boolean;
    validationError: string;
    errors: ById<IFieldErrorKeyValue>;
    productVersionHistoryPopup: boolean;
};

const SILEdOrderStyles = styled.div`
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };

    table {
        width: 100%;
        border-collapse: collapse;
    }
    
    .table-row {
        background-color: ${LIGHT_GREY_COLOR};
    };
    
    th, td {
        padding: 8px;
        text-align: center;
    }

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 1%;
        };

        table, thead, tbody, th, td, tr {
            display: block;
        }
    
        thead tr {
            display: none;
        }
    
        td {
            position: relative;
            padding-left: 50%;
            text-align: left;
        }
    
        td:before {
            content: attr(data-label);
            position: absolute;
            left: 10px;
            width: calc(50% - 20px);
            white-space: nowrap;
            text-align: left;
            font-weight: bold;
        }

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE_MD}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 1%;
        };

        table, thead, tbody, th, td, tr {
            display: block;
        }
    
        thead tr {
            display: none;
        }
    
        td {
            position: relative;
            padding-left: 50%;
            text-align: left;
        }
    
        td:before {
            content: attr(data-label);
            position: absolute;
            left: 10px;
            width: calc(50% - 20px);
            white-space: nowrap;
            text-align: left;
            font-weight: bold;
        }
    };
`;

type ISILEdOrderProps = RouteComponentProps
    & ISILEdOrderStoreProps
    & ISILEdOrderDispatchProps
    & ISILEdOrderOwnProps;

class SILEdOrder extends PureComponent<ISILEdOrderProps, ISILEdOrderState> {

    public constructor(props: ISILEdOrderProps) {
        super(props);
        this.state = {
            errors: {
                "customer": { key: "customer", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                // "subject": { key: "subject", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "address": { key: "address", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "selectedContact": { key: "selectedContact", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                // "pickup_Datetime": { key: "pickup_Datetime", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                // "sitecontact": { key: "sitecontact", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                // "sitecontact_Phone": { key: "sitecontact_Phone", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                // "pO_Number": { key: "pO_Number", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                // "customer_Job_Number": { key: "customer_Job_Number", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "transport_Company": { key: "transport_Company", message: FIELD_VALIDATOR_ERRORS.REQUIRED }, 
                "truck_Required": { key: "truck_Required", message: FIELD_VALIDATOR_ERRORS.REQUIRED }, 
            },
            collect: false,
            addContact: false,
            addAddress: false,
            addSalesOrder: false,
            addCustomer: false,
            addTransport: false,
            productVersionHistoryPopup: false,
            validationError: "",
            order: {
                customer_ID: 0,
                customer_Job_Number: "",
                id: 0,
                customer: "",
                address: "",
                pickupdate: "",
                pickuphour: "",
                pickupminute: "",
                sitecontact: "",
                sitecontact_Phone: "",
                pO_Number: "",
                emailuser: yesOrNoOptions[1].name,
                emailtransport: yesOrNoOptions[1].name,
                transport_Company: "",
                truck_Required: "",
                deliverydate: "",
                deliveryhour: "",
                deliveryminute: "",
                location: "SIL ED Warehouse",
                comments: "",
                packaged: yesOrNoOptions[1].name,
                completed: yesOrNoOptions[1].name,
                cancelled: "",
                truck_Number: "",
                trailer_Number: "",
                tare_Weight: "",
                gross_Weight: "",
                created_By: userName,
                modified_By: userName,
                in_Bin: "",
                status: "Assigned",
                pickup: "N/A",
                created: "",
                modified: "",
                total_Tonnage: 0,
                order_Type: "prepaid",
                transport_Company_Notes: "",
                pickup_Datetime: "",
                delivery_Datetime: "",
                last_Email_Sent_Timestamp: "",
                zone: "",
                hired_Truck_Rates: 0,
                sales_Order_Number: "",
                time_In: "",
                time_Out: "",
                truck_Code: "",
                explorer_Code: "",
                order_Contact: {
                    Email :"",
                    Contact: "",
                    Cell: "" ,
                    Phone: "",
                    Customer_ID: 0,
                },
                product_Order: [{
                    id: 0,
                    quantity: 0,
                    size: "",
                    per: "",
                    product: "",
                    code: "",
                    cW: yesOrNoOptions[1].name,
                    double_CW: yesOrNoOptions[1].name,
                    actual_Quantity: 0
                }]
            }
        };
    }

    public componentDidMount(): void {
        this.getOrders();
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISILEdOrderProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSalesOrderNumber !== prevProps.addSalesOrderNumber) {
                if (isSucceeded(this.props.addSalesOrderNumber)) {
                    this.setState({ addSalesOrder: false });
                    if(hasPayload(this.props.getToken))
                        this.props.getSalesOrderNumbersByCustomerIDRequest({
                            token: this.props.getToken.payload.response.token,
                            request: {
                                Customer_ID: Number(this.state.order.customer_ID)
                            }
                        });
                };
            };

            if (this.props.addOrder !== prevProps.addOrder) {
                if (isSucceeded(this.props.addOrder)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateOrder !== prevProps.updateOrder) {
                if (isSucceeded(this.props.updateOrder)) {
                    this.handleCancel();
                };
            };
        };

    };


    public render(): ReactNode {

        const { order, collect, addTransport, errors, validationError, addCustomer, addAddress, addContact, productVersionHistoryPopup, addSalesOrder } = this.state;
        const isAdd = order.id > 0 ? false : true;
        const isCompleted = order.status === "Completed" ? true : undefined;
        const isProductReady = order.status === "Ready for pickup" ? true : undefined;

        const { getSimosProductsList, getToken, getSimosCustomersList, getSimosTransportCompanyList, getSimosCustomerContacts,
            updateOrder, addOrder, getSimosTrucks, getSimosOrderProductVersionHistory, getSalesOrderNumbersByCustomerID, addSalesOrderNumber
        } = this.props;

        const getRole = hasPayload(getToken) && getToken.payload.response.simosAccess.access;

        const simosOrderProductVersionHistorypopupData = hasPayload(getSimosOrderProductVersionHistory) ? getSimosOrderProductVersionHistory.payload.response : [];

        let products = hasPayload(getSimosProductsList) ? getSimosProductsList.payload.response : [];
        products = products.length > 0 ? products.filter((x) => x.location === order.location) : [];

        const productDropdownOptions = products.reduce((acc: any, current: any) => {
            if (!acc.some((item: any) => item.description === current.description)) {
              acc.push(current);
            }
            return acc;
          }, []);

        let companies = hasPayload(getSimosTransportCompanyList) ? getSimosTransportCompanyList.payload.response : [];
        companies = companies.length > 0 ? companies.filter((x) => x.active === "Yes") : companies;

        let customers = hasPayload(getSimosCustomersList) ? getSimosCustomersList.payload.response : [];
        customers = customers.length > 0 ? customers.filter((x) => x.active === "Yes") : [];

        const selectedCust = order.customer_ID !== 0 ? customers.find(x => x.id === order.customer_ID) : undefined;

        const contacts = hasPayload(getSimosCustomerContacts) ? getSimosCustomerContacts.payload.response : [];
        const salesOrderNumbers = hasPayload(getSalesOrderNumbersByCustomerID) ? getSalesOrderNumbersByCustomerID.payload.response : [];
        const simosTrucksInfo = hasPayload(getSimosTrucks) ? getSimosTrucks.payload.response : [];
        const findContact = contacts.length > 0 && selectedCust ? contacts.filter((x) => x.customer_ID === selectedCust.id) : [];

        const onContactSelect = (value: string): void => this.onContact(value, findContact);

        return (
            <PageSpacing title="SIMOS - SIL ED Order" description="SIMOS - SIL ED Order" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <SILEdOrderStyles>

                        <div className="hide-on-phone">
                            <SimosSubHeader
                                {...this.props}
                            />
                        </div>

                        <div className="show-on-phone">
                            <SimosSubHeaderMobile
                                {...this.props}
                            />
                        </div>

                        <LAMaroonPaper>
                            <LAPaperWithPadding>
                                <LAGrid>
                                    <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleClose} />
                                    <LAGridItem xs={12}>
                                        <h2 className="text-center">{!isAdd ? `SIL ED Order #${order.id} ` : "ADD SIL ED Order"} </h2>
                                        <hr />
                                    </LAGridItem>

                                    <LAGridItem xs={11} sm={5} md={3}>
                                        <strong>Customer</strong>
                                        <LAAutoComplete
                                            filterSelectedOptions={true}
                                            autoHighlight={true}
                                            selectionRemove={undefinedFunction}
                                            dropDownPlaceHolder=""
                                            getOptionLabel="customer_Display"
                                            name="customer"
                                            option={customers}
                                            multiple={false}
                                            onChange={this.handleDropDownChange}
                                            disabled={!isAdd ? true : undefined}
                                            value={selectedCust ? selectedCust : ""}
                                            defaultValue={selectedCust ? selectedCust : ""}
                                            errorText={errors["customer"] ? errors["customer"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={1}>
                                        <br />
                                        <LAIconButton
                                            label="Add New"
                                            icon={<AddIcon />}
                                            onClick={this.handleAddCustomer}
                                            disabled={!isAdd ? true : undefined}
                                        />
                                    </LAGridItem>

                                    {isAdd ? <LAGridItem xs={11} sm={5} md={3}>
                                            <strong>Sales Order #</strong>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder=""
                                                getOptionLabel="sales_Order_Number"
                                                name="sales_Order_Number"
                                                option={salesOrderNumbers}
                                                disabled={!selectedCust ? true : undefined}
                                                multiple={false}
                                                onChange={this.handleDropDownChange}
                                                value={order.sales_Order_Number ? salesOrderNumbers.find(x => x.sales_Order_Number === order.sales_Order_Number) : ""}
                                                defaultValue={order.sales_Order_Number ? salesOrderNumbers.find(x => x.sales_Order_Number === order.sales_Order_Number) : ""}
                                            />
                                        </LAGridItem> : 
                                        <LAGridItem xs={11} sm={5} md={3}>
                                            <strong>Sales Order #</strong>
                                            <LATextField
                                                fullWidth={true}
                                                name="sales_Order_Number"
                                                varient="outlined"
                                                onChange={undefinedFunction}
                                                label=""
                                                value={order.sales_Order_Number ?? ""}
                                                disabled={true}
                                            />
                                        </LAGridItem>
                                    }

                                    <LAGridItem xs={1}>
                                        <br />
                                        {selectedCust && <LAIconButton
                                            label="Add New"
                                            icon={<AddIcon />}
                                            onClick={this.handleAddSalesOrder}
                                            disabled={!isAdd ? true : undefined}
                                        />}
                                    </LAGridItem>

                                    <LAGridItem xs={11} sm={5} md={3}>
                                        <strong>Delivery Address</strong>
                                        {isAdd ? <LAAutoComplete
                                            filterSelectedOptions={true}
                                            autoHighlight={true}
                                            selectionRemove={undefinedFunction}
                                            dropDownPlaceHolder=""
                                            getOptionLabel="address"
                                            name="address"
                                            multiple={false}
                                            onChange={this.handleDropDownChange}
                                            disabled={!isAdd ? true : undefined}
                                            option={selectedCust ? selectedCust.address : []}
                                            value={(selectedCust && selectedCust.address) ? selectedCust.address.find(x => x.address === order.address) : ""}
                                            defaultValue={(selectedCust && selectedCust.address) ? selectedCust.address.find(x => x.address === order.address) : ""}
                                            errorText={errors["address"] ? errors["address"].message : undefined}
                                        /> : 
                                        <LATextField
                                            fullWidth={true}
                                            name="address"
                                            varient="outlined"
                                            onChange={undefinedFunction}
                                            label=""
                                            value={order.address ?? ""}
                                            disabled={true}
                                        />}
                                    </LAGridItem>

                                    <LAGridItem xs={1}>
                                        <br />
                                        <LAIconButton
                                            label="Add New"
                                            icon={<AddIcon />}
                                            onClick={this.handleAddAddress}
                                            disabled={!isAdd ? true : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <LABluePaper>
                                            <LAGrid>
                                                <LAGridItem xs={11} sm={5} md={3}>
                                                    <strong>Customer Contact</strong>
                                                    <DevExtremePopupLookup
                                                        errors={errors}
                                                        disabled={isCompleted}
                                                        displayExp="contact"
                                                        data={findContact}
                                                        height={500}
                                                        id="contact-search"
                                                        name="selectedContact"
                                                        onClick={onContactSelect}
                                                        placeHolder=""
                                                        currentVal={order.order_Contact && order.order_Contact ? order.order_Contact : ""}
                                                        columns={[
                                                            { name: "contact", caption: "Name", type: "string" },
                                                            { name: "phone", caption: "Phone", type: "string" },
                                                            { name: "cell", caption: "Cell", type: "string" },
                                                            { name: "email", caption: "Email", type: "string" },
                                                        ]}
                                                    />
                                                </LAGridItem>

                                                {selectedCust && <LAGridItem xs={1}>
                                                    <br />
                                                    <LAIconButton
                                                        label="Add New"
                                                        icon={<AddIcon />}
                                                        onClick={this.handleAddContact}
                                                        disabled={!isAdd ? true : undefined}
                                                    />
                                                </LAGridItem>}
                                            </LAGrid>

                                            <LAGridItem xs={12}>
                                                {order.order_Contact && order.order_Contact ? 
                                                    <>
                                                        <br />
                                                        <LAPaperWithLessPadding>
                                                            <LAGrid className="text-center">

                                                                <LAGridItem xs={6} sm={3}>
                                                                    <ContactIcon color={ORANGE_COLOR} />
                                                                    <strong>{order.order_Contact.Contact}</strong>
                                                                </LAGridItem>

                                                                <LAGridItem xs={6} sm={3}>
                                                                    <PhoneIcon color={BLUE_COLOR} />
                                                                    <strong>{order.order_Contact.Phone}</strong>
                                                                </LAGridItem>

                                                                <LAGridItem xs={6} sm={3}>
                                                                    <MdOutlinePhoneIphone color={RED_COLOR} size={21}/>
                                                                    {/* <FaxIcon color={RED_COLOR} /> */}
                                                                    <strong>{order.order_Contact.Cell}</strong>
                                                                </LAGridItem>
                                                                
                                                                <LAGridItem xs={6} sm={3}>
                                                                    <EmailIcon color={GREEN_COLOR} />
                                                                    <strong>{order.order_Contact.Email}</strong>
                                                                </LAGridItem>


                                                            </LAGrid>
                                                        </LAPaperWithLessPadding>
                                                        <br />
                                                    </>
                                                : ""}
                                            </LAGridItem>
                                        </LABluePaper>
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <strong>Scheduled Pickup Date/Time</strong>
                                        <LATextField
                                            fullWidth={true}
                                            name="pickup_Datetime"
                                            varient="outlined"
                                            onChange={this.handleDate}
                                            label=""
                                            type="datetime-local"
                                            value={order.pickup_Datetime ?? ""}
                                            disabled={isCompleted}
                                            errorText={errors["pickup_Datetime"] ? errors["pickup_Datetime"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <strong>Delivery Date/Time</strong>
                                        <LATextField
                                            fullWidth={true}
                                            name="delivery_Datetime"
                                            varient="outlined"
                                            onChange={this.handleDate}
                                            label=""
                                            type="datetime-local"
                                            disabled={isCompleted}
                                            value={order.delivery_Datetime ?? ""}
                                            errorText={errors["delivery_Datetime"] ? errors["delivery_Datetime"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <strong>Site Contact</strong>
                                        <LATextField
                                            varient="outlined"
                                            label=""
                                            fullWidth={true}
                                            name="sitecontact"
                                            value={order.sitecontact}
                                            onChange={this.onChange}
                                            disabled={isCompleted}
                                            errorText={errors["sitecontact"] ? errors["sitecontact"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <strong>Site Phone</strong>
                                        <LATextField
                                            varient="outlined"
                                            label=""
                                            fullWidth={true}
                                            type="number"
                                            name="sitecontact_Phone"
                                            onChange={this.onChange}
                                            disabled={isCompleted}
                                            value={order.sitecontact_Phone}
                                            errorText={errors["sitecontact_Phone"] ? errors["sitecontact_Phone"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <strong>PO #</strong>
                                        <LATextField
                                            label=""
                                            name="pO_Number"
                                            fullWidth={true}
                                            varient="outlined"
                                            onChange={this.onChange}
                                            value={order.pO_Number}
                                            disabled={isCompleted}
                                            errorText={errors["pO_Number"] ? errors["pO_Number"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <strong>Customer Job #</strong>
                                        <LATextField
                                            label=""
                                            name="customer_Job_Number"
                                            fullWidth={true}
                                            varient="outlined"
                                            onChange={this.onChange}
                                            disabled={isCompleted}
                                            value={order.customer_Job_Number ?? ""}
                                            errorText={errors["customer_Job_Number"] ? errors["customer_Job_Number"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <LACheckBox
                                            name="collect"
                                            label="Collect"
                                            value={collect}
                                            onChange={this.onCollect}
                                            disabled={isCompleted}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={11} sm={5} md={3}>
                                        <strong>Transport Company</strong>
                                        <LAAutoComplete
                                            filterSelectedOptions={true}
                                            autoHighlight={true}
                                            selectionRemove={undefinedFunction}
                                            dropDownPlaceHolder=""
                                            getOptionLabel="transport_Company"
                                            name="transport_Company"
                                            option={companies}
                                            multiple={false}
                                            disabled={collect ? true : isCompleted}
                                            onChange={this.handleDDWithName}
                                            value={companies && order.transport_Company ? companies.find(x => x.transport_Company === order.transport_Company) : ""}
                                            defaultValue={companies && order.transport_Company ? companies.find(x => x.transport_Company === order.transport_Company) : ""}
                                            errorText={errors["transport_Company"] ? errors["transport_Company"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={1}>
                                        <br />
                                        <LAIconButton
                                            label="Add New"
                                            icon={<AddIcon />}
                                            onClick={this.handleTransportPopup}
                                            disabled={!isAdd || collect ? true : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <strong>Truck Size</strong>
                                        <LAAutoComplete
                                            filterSelectedOptions={true}
                                            autoHighlight={true}
                                            selectionRemove={undefinedFunction}
                                            dropDownPlaceHolder=""
                                            getOptionLabel="name"
                                            name="truck_Required"
                                            option={truckSizeList}
                                            multiple={false}
                                            disabled={collect ? true : isCompleted}
                                            onChange={this.handleDDWithName}
                                            value={truckSizeList && order.truck_Required ? truckSizeList.find(x => x.name === order.truck_Required) : ""}
                                            defaultValue={truckSizeList && order.truck_Required ? truckSizeList.find(x => x.name === order.truck_Required) : ""}
                                            errorText={errors["truck_Required"] ? errors["truck_Required"].message : undefined}
                                        />
                                    </LAGridItem>

                                    {collect && <LAGridItem xs={12} sm={6} md={4}>
                                        <strong>Transport Company/Truck Size Notes</strong>
                                        <LATextField
                                            fullWidth={true}
                                            varient="outlined"
                                            onChange={this.onChange}
                                            name="transport_Company_Notes"
                                            value={order.transport_Company_Notes}
                                            disabled={isCompleted}
                                            label=""
                                            errorText={errors["transport_Company_Notes"] ? errors["transport_Company_Notes"].message : undefined}
                                        />
                                    </LAGridItem>}

                                    {order.transport_Company === "Yellowhead Trucking" && order.order_Type === "prepaid" && <LAGridItem xs={12} sm={6} md={4}>
                                        <strong>Truck Code</strong>
                                        <LATextField
                                            fullWidth={true}
                                            varient="outlined"
                                            onChange={this.onChange}
                                            name="truck_Code"
                                            value={order.truck_Code}
                                            label=""
                                            disabled={isCompleted}
                                            errorText={errors["truck_Code"] ? errors["truck_Code"].message : undefined}
                                        />
                                    </LAGridItem>}

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <strong>Zone</strong>
                                        <LATextField
                                            label=""
                                            name="zone"
                                            fullWidth={true}
                                            varient="outlined"
                                            value={order.zone}
                                            disabled={isCompleted}
                                            onChange={this.onChange}
                                            errorText={errors["zone"] ? errors["zone"].message : undefined}
                                        />
                                    </LAGridItem>

                                    {!isAdd && <LAGridItem xs={12} sm={6} md={4}>
                                        <LACheckBox
                                            label="Email User"
                                            onChange={this.onEmailUser}
                                            value={order.emailuser === yesOrNoOptions[0].name ? true : false}
                                        />
                                    </LAGridItem>}

                                    {!isAdd && order.order_Type === "prepaid" && <LAGridItem xs={12} sm={6} md={4}>
                                        <LACheckBox
                                            label="Email Transport Company"
                                            onChange={this.onEmailTransport}
                                            value={order.emailtransport === yesOrNoOptions[0].name ? true : false}
                                        />
                                    </LAGridItem>}

                                    <LAGridItem xs={12}>
                                        <LAGreyPaper>
                                            <LAGridItem xs={12}>
                                                <h2 className="text-center">Products</h2><hr />
                                            </LAGridItem>
                                            <LAGridItem xs={12}>

                                                <TableContainer component={LAPaperWithLessPadding}>
                                                    {/* <h2 className="text-center">Products</h2> */}
                                                    <strong>Location</strong>
                                                    <RadioGroup className="location-btn" row aria-label="" name="radioGroup" value={order.location} onChange={this.onProductClick}>
                                                        <FormControlLabel value="SIL ED Warehouse" control={<Radio size="small" />} label="SIL ED Warehouse" disabled={isCompleted || isProductReady}/>
                                                        <FormControlLabel value="Bulk" control={<Radio size="small" />} label="Bulk" disabled={isCompleted || isProductReady}/>
                                                    </RadioGroup>

                                                    <LAIconButton
                                                        label="Add"
                                                        icon={<AddIcon />}
                                                        onClick={this.onProductAdd}
                                                        disabled={(isCompleted || order.status === "Ready for pickup") ? true : undefined}
                                                    />

                                                    <Table size="small" aria-label="Product">
                                                        <TableHead className="table-head">
                                                            {order.location === "" || order.location === "SIL ED Warehouse" ? (
                                                                <TableRow>
                                                                    <TableCell align="center"><strong>Product</strong></TableCell>
                                                                    <TableCell align="center"><strong>Size</strong></TableCell>
                                                                    <TableCell align="center"><strong>Bags Per Pallet</strong></TableCell>
                                                                    <TableCell align="center"><strong># of Pallets</strong></TableCell>
                                                                    <TableCell align="center"><strong>Product Code</strong></TableCell>
                                                                    <TableCell align="center"><strong>C&W</strong></TableCell>
                                                                    <TableCell align="center"><strong>Double C&W</strong></TableCell>
                                                                    <TableCell align="center"><strong>{!isAdd ? "Actual Quantity" : "Total Quantity"}</strong></TableCell>
                                                                    {!isAdd && <TableCell align="center"><strong>Batch</strong></TableCell>}
                                                                    <TableCell align="center"><strong></strong></TableCell>
                                                                    {!isAdd && <TableCell align="center"><strong>Version History</strong></TableCell>}
                                                                </TableRow>
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell align="center"><strong>Product</strong></TableCell>
                                                                    <TableCell align="center"><strong>Size</strong></TableCell>
                                                                    <TableCell align="center"><strong>Product Code</strong></TableCell>
                                                                    <TableCell align="center"><strong>Requested Quantity(MT)</strong></TableCell>
                                                                    {!isAdd && <TableCell align="center"><strong>Actual Quantity(MT)</strong></TableCell>}
                                                                    {!isAdd && <TableCell align="center"><strong>Batch</strong></TableCell>}
                                                                    <TableCell align="center"><strong></strong></TableCell>
                                                                    {!isAdd && <TableCell align="center"><strong>Version History</strong></TableCell>}
                                                                </TableRow>
                                                            )}
                                                        </TableHead>

                                                        <TableBody>
                                                            {order.product_Order.map((x, index) => {
                                                                const isBulk = order.location === "Bulk";
                                                                const onCheckBox = (checked: boolean, name?: string) => this.onProductCheckBox(checked, index, name ?? "");
                                                                const onTChange = (name: string, value: string) => this.onTableChange(name, value, index);
                                                                const onDeleteProduct = (event: any) => this.onTableProductDelete(index);
                                                                const onProductChange = (event: unknown, value: string, name?: string) => this.onTableDropDownChange(event, value, index, name ?? "", "description");
                                                                const onSize = (event: unknown, value: string, name?: string) => this.onTableDropDownChange(event, value, index, name ?? "", isBulk ? "truck" : "size");
                                                                const currentPro = products.findIndex(y => y.description === x.product);

                                                                const proSize = products.filter(y => y.location === order.location && products[currentPro] ? products[currentPro].description === y.description : false);

                                                                return (
                                                                    <TableRow key={index} className={index % 2 ? "" : "table-row"}>
                                                                        <TableCell align="center" data-label="Product">
                                                                            {x.id === 0 ? <LAAutoComplete
                                                                                filterSelectedOptions={true}
                                                                                autoHighlight={true}
                                                                                selectionRemove={undefinedFunction}
                                                                                dropDownPlaceHolder="Product"
                                                                                getOptionLabel="description"
                                                                                name="product"
                                                                                option={productDropdownOptions}
                                                                                multiple={false}
                                                                                onChange={onProductChange}
                                                                                disabled={!isAdd && x.id > 0 ? true : undefined}
                                                                                value={products && x.product ? products.find(y => y.description === x.product) : ""}
                                                                                defaultValue={products && x.product ? products.find(y => y.description === x.product) : ""}
                                                                            /> :
                                                                            <LATextArea
                                                                                minRows={1}
                                                                                rowsMax={1}
                                                                                name="product"
                                                                                fullWidth={true}
                                                                                label="Product"
                                                                                value={x.product}
                                                                                disabled={true}
                                                                                onChange={undefinedFunction}
                                                                            />}
                                                                        </TableCell>

                                                                        {!isBulk ? <TableCell align="center" data-label="Size">
                                                                            {x.id === 0 ? <LAAutoComplete
                                                                                filterSelectedOptions={true}
                                                                                autoHighlight={true}
                                                                                selectionRemove={undefinedFunction}
                                                                                dropDownPlaceHolder="Size"
                                                                                getOptionLabel="size"
                                                                                name="size"
                                                                                multiple={false}
                                                                                onChange={onSize}
                                                                                option={proSize}
                                                                                disabled={!isAdd && x.id > 0 ? true : undefined}
                                                                                value={proSize && x.size ? proSize.find(y => y.size === x.size) : ""}
                                                                                defaultValue={proSize && x.size ? proSize.find(y => y.size === x.size) : ""}
                                                                            /> :
                                                                            <LATextArea
                                                                                minRows={1}
                                                                                rowsMax={1}
                                                                                name="size"
                                                                                fullWidth={true}
                                                                                label="Size"
                                                                                value={x.size}
                                                                                disabled={true}
                                                                                onChange={undefinedFunction}
                                                                            />}
                                                                        </TableCell> :

                                                                        <TableCell align="center" data-label="Size">
                                                                            {x.id === 0 ? <LAAutoComplete
                                                                                filterSelectedOptions={true}
                                                                                autoHighlight={true}
                                                                                selectionRemove={undefinedFunction}
                                                                                dropDownPlaceHolder="Size"
                                                                                getOptionLabel="truck"
                                                                                name="size"
                                                                                multiple={false}
                                                                                onChange={onSize}
                                                                                option={simosTrucksInfo }
                                                                                disabled={!isAdd && x.id > 0 ? true : undefined}
                                                                                value={simosTrucksInfo && x.size ? simosTrucksInfo.find(y => y.truck === x.size) : ""}
                                                                                defaultValue={simosTrucksInfo && x.size ? simosTrucksInfo.find(y => y.truck === x.size) : ""}
                                                                            /> :
                                                                            <LATextArea
                                                                                minRows={1}
                                                                                rowsMax={1}
                                                                                name="size"
                                                                                fullWidth={true}
                                                                                label="Size"
                                                                                value={x.size}
                                                                                disabled={true}
                                                                                onChange={undefinedFunction}
                                                                            />}
                                                                        </TableCell>}

                                                                        {!isBulk && (
                                                                            <>
                                                                                <TableCell align="center" data-label="Bags Per Pallet">
                                                                                    <LATextArea
                                                                                        minRows={1}
                                                                                        rowsMax={1}
                                                                                        type="number"
                                                                                        name="per"
                                                                                        fullWidth={true}
                                                                                        label="Bags Per Pallet"
                                                                                        value={x.per}
                                                                                        onChange={onTChange}
                                                                                        disabled={isCompleted || isProductReady}
                                                                                    />
                                                                                </TableCell>

                                                                                <TableCell align="center" data-label="# of Pallets">
                                                                                    <LATextArea
                                                                                        minRows={1}
                                                                                        rowsMax={1}
                                                                                        type="number"
                                                                                        name="quantity"
                                                                                        fullWidth={true}
                                                                                        label="# of Pallets"
                                                                                        value={x.quantity}
                                                                                        disabled={isCompleted || isProductReady}
                                                                                        onChange={onTChange}
                                                                                    />
                                                                                </TableCell>
                                                                            </>
                                                                        )}

                                                                        <TableCell align="center" data-label="Product Code">
                                                                            <LATextArea
                                                                                minRows={1}
                                                                                rowsMax={1}
                                                                                name="code"
                                                                                fullWidth={true}
                                                                                label="Product Code"
                                                                                value={x.code}
                                                                                disabled={isCompleted || isProductReady}
                                                                                onChange={onTChange}
                                                                            />
                                                                        </TableCell>

                                                                        {isBulk && (
                                                                            <TableCell align="center" data-label="Requested Quantity(MT)">
                                                                                <LATextArea
                                                                                    minRows={1}
                                                                                    rowsMax={1}
                                                                                    name="quantity"
                                                                                    fullWidth={true}
                                                                                    label="Requested Quantity(MT)"
                                                                                    value={x.quantity}
                                                                                    disabled={isCompleted || isProductReady}
                                                                                    onChange={onTChange}
                                                                                />
                                                                            </TableCell>
                                                                        )}

                                                                        {!isBulk && (
                                                                            <>
                                                                                <TableCell align="center" data-label="C&W">
                                                                                    <LACheckBox
                                                                                        name="cW"
                                                                                        label="C&W"
                                                                                        onChange={onCheckBox}
                                                                                        disabled={isCompleted || isProductReady}
                                                                                        value={x.cW === "Yes" ? true : false}
                                                                                    />
                                                                                </TableCell>

                                                                                <TableCell align="center" data-label="Double C&W">
                                                                                    <LACheckBox
                                                                                        name="double_CW"
                                                                                        label="Double C&W"
                                                                                        onChange={onCheckBox}
                                                                                        disabled={isCompleted || isProductReady}
                                                                                        value={x.double_CW === "Yes" ? true : false}
                                                                                    />
                                                                                </TableCell>

                                                                                {isAdd && (
                                                                                    <TableCell align="center" data-label="Total Quantity">
                                                                                        <LATextArea
                                                                                            minRows={1}
                                                                                            rowsMax={1}
                                                                                            name="Total_Quantity"
                                                                                            fullWidth={true}
                                                                                            disabled={true}
                                                                                            label="Total Quantity"
                                                                                            value={+x.per * x.quantity}
                                                                                            onChange={undefinedFunction}
                                                                                        />
                                                                                    </TableCell>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        {!isAdd && (
                                                                            <TableCell align="center" data-label="Actual Quantity(MT)">
                                                                                <LATextArea
                                                                                    minRows={1}
                                                                                    rowsMax={1}
                                                                                    name="actual_Quantity"
                                                                                    fullWidth={true}
                                                                                    onChange={onTChange}
                                                                                    disabled={isCompleted}
                                                                                    label="Actual Quantity"
                                                                                    value={x.actual_Quantity}
                                                                                />
                                                                            </TableCell>
                                                                        )}

                                                                        {!isAdd && (
                                                                            <TableCell align="center" data-label="batch">
                                                                                <LATextArea
                                                                                    minRows={1}
                                                                                    rowsMax={2}
                                                                                    name="batch"
                                                                                    onChange={onTChange}
                                                                                    disabled={true}
                                                                                    label="Batch"
                                                                                    value={x.batch ?? ""}
                                                                                />
                                                                            </TableCell>
                                                                        )}
                                                                        <TableCell>
                                                                            <LAIconButton
                                                                                label="Delete"
                                                                                icon={<DeleteIcon />}
                                                                                onClick={onDeleteProduct} 
                                                                                disabled={x.id > 0 && order.status !== "Assigned" ? true : undefined}
                                                                            />
                                                                        </TableCell>
                                                                        {!isAdd && x.id > 0 && 
                                                                            <TableCell align="left" data-label="Version History">
                                                                                <LAButton label="View" onClick={() => this.handleProductVersionHistory(x.id)} />
                                                                            </TableCell>
                                                                        }
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </LAGridItem>
                                        </LAGreyPaper>
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={12} md={12}>
                                        <strong>Comments</strong>
                                        <LATextArea
                                            minRows={5}
                                            rowsMax={10}
                                            name="comments"
                                            label=""
                                            fullWidth={true}
                                            varient="outlined"
                                            value={order.comments}
                                            onChange={this.onChange}
                                        />
                                    </LAGridItem>

                                    {!isAdd && <>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LACheckBox
                                                label={order.location === "Bulk" ? "In Bin" : "Packaged"}
                                                onChange={this.onPackage}
                                                disabled={isCompleted}
                                                value={order.packaged === yesOrNoOptions[0].name ? true : false}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LACheckBox
                                                label="Completed"
                                                onChange={this.onComplete}
                                                disabled={isCompleted}
                                                value={order.completed === yesOrNoOptions[0].name ? true : false}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <strong>Truck #</strong>
                                            <LATextField
                                                name="truck_Number"
                                                label=""
                                                fullWidth={true}
                                                varient="outlined"
                                                disabled={isCompleted}
                                                value={order.truck_Number ?? ""}
                                                onChange={this.onChange}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <strong>Trailer #</strong>
                                            <LATextField
                                                name="trailer_Number"
                                                label=""
                                                fullWidth={true}
                                                varient="outlined"
                                                disabled={isCompleted}
                                                value={order.trailer_Number ?? ""}
                                                onChange={this.onChange}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <strong>Tare Weight (Kg)</strong>
                                            <LATextField
                                                name="tare_Weight"
                                                label=""
                                                fullWidth={true}
                                                varient="outlined"
                                                type="number"
                                                disabled={isCompleted}
                                                value={order.tare_Weight}
                                                onChange={this.onChange}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <strong>Gross Weight (Kg)</strong>
                                            <LATextField
                                                name="gross_Weight"
                                                label=""
                                                fullWidth={true}
                                                type="number"
                                                varient="outlined"
                                                disabled={isCompleted}
                                                value={order.gross_Weight}
                                                onChange={this.onChange}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <strong>Total Tonnage</strong>
                                            <LATextField
                                                name="total_Tonnage"
                                                label=""
                                                fullWidth={true}
                                                type="number"
                                                varient="outlined"
                                                disabled={true}
                                                value={order.total_Tonnage}
                                                onChange={undefinedFunction}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <strong>Time In</strong>
                                            <LATextField
                                                fullWidth={true}
                                                name="time_In"
                                                onChange={this.handleDate}
                                                label=""
                                                varient="outlined"
                                                disabled={isCompleted}
                                                type="datetime-local"
                                                value={order.time_In ?? ""}
                                                errorText={errors["time_In"] ? errors["time_In"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <strong>Time Out</strong>
                                            <LATextField
                                                fullWidth={true}
                                                name="time_Out"
                                                onChange={this.handleDate}
                                                label=""
                                                varient="outlined"
                                                type="datetime-local"
                                                disabled={isCompleted}
                                                value={order.time_Out ?? ""}
                                                errorText={errors["time_Out"] ? errors["time_Out"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} className="text-center">
                                            <br />
                                            <br />
                                            {!isAdd && <LASecondaryButton
                                                label="Cancel Order"
                                                disabled={isCompleted}
                                                onClick={this.handleCancelOrder}
                                            />}
                                            <br />
                                            <br />
                                        </LAGridItem>

                                        <LAGrid>
                                            <LAGridItem xs={12} sm={6} md={3}>
                                                <LATextField
                                                    fullWidth={true}
                                                    name="created"
                                                    label="Created"
                                                    varient="outlined"
                                                    disabled={true}
                                                    value={order.created ? new Date(order.created).toLocaleString() : ""}
                                                    onChange={undefinedFunction}
                                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12} sm={6} md={3}>
                                                <LATextField
                                                    fullWidth={true}
                                                    name="created_By"
                                                    label="Created By"
                                                    disabled={true}
                                                    varient="outlined"
                                                    value={order.created_By}
                                                    onChange={undefinedFunction}
                                                    errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                                />
                                            </LAGridItem>
                                            

                                            <LAGridItem xs={12} sm={6} md={3}>
                                                <LATextField
                                                    fullWidth={true}
                                                    name="modified"
                                                    label="Last Modified"
                                                    varient="outlined"
                                                    disabled={true}
                                                    value={order.modified ? new Date(order.modified).toLocaleString() : ""}
                                                    onChange={undefinedFunction}
                                                    errorText={errors["modified"] ? errors["modified"].message : undefined}
                                                />
                                            </LAGridItem>
                                            

                                            <LAGridItem xs={12} sm={6} md={3}>
                                                <LATextField
                                                    fullWidth={true}
                                                    name="modified_By"
                                                    label="Modified By"
                                                    varient="outlined"
                                                    disabled={true}
                                                    value={order.modified_By}
                                                    onChange={undefinedFunction}
                                                    errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                                />
                                            </LAGridItem>
                                        </LAGrid> 
                                    </>}

                                    <LAGridItem xs={12}>
                                        {validationError && <LAErrorBox text={validationError} />}

                                        <hr />
                                        <LASaveAndCancelButton
                                            onSave={this.onSave}
                                            onCancel={this.handleClose}
                                            disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                        />
                                    </LAGridItem>

                                    <LAPopover open={addTransport} onClose={this.handleTransportPopup} anchorRef={null} transformOrigin={{ vertical: "top", horizontal: "center" }} anchorOrigin={{ vertical: "top", horizontal: "left" }}>
                                        <AddUpdateSimosTransportCompany {...this.props} onClose={this.handleTransportPopup}/>
                                    </LAPopover>

                                    <LAPopover open={addCustomer} onClose={this.handleAddCustomer} anchorRef={null} transformOrigin={{ vertical: "top", horizontal: "center" }} anchorOrigin={{ vertical: "top", horizontal: "left" }}>
                                        <AddUpdateSimosCustomer {...this.props} onClose={this.handleAddCustomer}/>
                                    </LAPopover>

                                    <LAPopover open={addAddress} onClose={this.handleAddAddress} anchorRef={null} transformOrigin={{ vertical: "top", horizontal: "center" }} anchorOrigin={{ vertical: "top", horizontal: "left" }}>
                                        <AddUpdateSimosCustomer {...this.props} id={selectedCust ? selectedCust.id : undefined} addressOnly={true} onClose={this.handleAddAddress}/>
                                    </LAPopover>

                                    <LAPopover open={addContact} onClose={this.handleAddContact} anchorRef={null} transformOrigin={{ vertical: "top", horizontal: "center" }} anchorOrigin={{ vertical: "top", horizontal: "left" }}>
                                        <AddUpdateSimosCustomerContact {...this.props} onClose={this.handleAddContact}/>
                                    </LAPopover>

                                    <AddSalesOrderNumberPopup 
                                        open={addSalesOrder}
                                        onSave={this.handleSaveSalesOrderNumber}
                                        onClose={this.handleAddSalesOrder}
                                    />

                                    {productVersionHistoryPopup && <ProductVersionHistory
                                        data={simosOrderProductVersionHistorypopupData}
                                        onClose={this.closeProductVersionHistoryPopUp}
                                        open={productVersionHistoryPopup}
                                        status={getSimosOrderProductVersionHistory.kind}
                                    />}

                                </LAGrid>

                                <RequestStatus requestStatus={addSalesOrderNumber.kind} successMessage="Sales Order Number successfully added" failedMessage={addSalesOrderNumber.kind === STATUS_ENUM.FAILED ? addSalesOrderNumber.message : undefined}/>
                                <RequestStatus requestStatus={addOrder.kind} successMessage="Order successfully saved" failedMessage={addOrder.kind === STATUS_ENUM.FAILED ? addOrder.message : undefined}/>
                                <RequestStatus requestStatus={updateOrder.kind} successMessage="Order successfully updated" failedMessage={updateOrder.kind === STATUS_ENUM.FAILED ? updateOrder.message : undefined}/>
                            </LAPaperWithPadding>
                        </LAMaroonPaper>
                    </SILEdOrderStyles>
                }
            </PageSpacing>
        );
    }

    // private onContactRemove = (idx: number): void => {
    //     const customer_Contact = [...this.state.order.customer_Contact];
    //     customer_Contact.splice(idx, 1);
    //     this.setState({
    //         ...this.state,
    //         order: {
    //             ...this.state.order,
    //             customer_Contact
    //         }
    //     });
    // };

    private onComplete = (value: boolean): void => {
        this.setState({
            ...this.state,
            order: {
                ...this.state.order,
                completed: value ? yesOrNoOptions[0].name : yesOrNoOptions[1].name
            }
        });
    };

    private onPackage = (value: boolean): void => {
        this.setState({
            ...this.state,
            order: {
                ...this.state.order,
                packaged: value ? yesOrNoOptions[0].name : yesOrNoOptions[1].name
            }
        });
    };

    private onContact = (value: string, list: ISimosCustomerContacts[]): void => {
        let errors = this.state.errors;
        const idx = list.findIndex((x) => x.contact === value);
        if (idx >= 0) {
            const rec = list[idx];

            if(rec) {
                delete errors["selectedContact"];
                this.setState({
                    ...this.state,
                    order: {
                        ...this.state.order,
                        order_Contact: {
                            Email: rec.email,
                            Contact: rec.contact,
                            Cell: rec.cell,
                            Phone: rec.phone,
                            Customer_ID: rec.customer_ID,
                            // Selected: "Yes"
                        }
                    },
                    errors
                });
            }
        }
    };

    private handleCancelOrder = async (): Promise<void> => {
        await this.setState({
            ...this.state,
            order: {
                ...this.state.order,
                cancelled: yesOrNoOptions[0].name
            }
        });

        this.onSave();
    };

    private validateProductsTable = (): string => {
        let productError = "";
        const order = this.state.order;
        if(order.product_Order.length === 0) {
            productError = "At least one product must be added!"
        }
        else {
            if(order.location === "SIL ED Warehouse") {
                const isMissingFields = order.product_Order.some((obj: IProductOrder) => !obj.product || !obj.size || !obj.quantity || !obj.per);
                if(isMissingFields) {
                    productError = "Please fill product, size, bags per pallet and # of pallets fields in products table."
                }
            }
            else {
                const isMissingFields = order.product_Order.some((obj: IProductOrder) => !obj.product || !obj.size);
                if(isMissingFields) {
                    productError = "Please fill product and size fields in products table."
                }
            }
        }
        return productError
    }

    private validateForm = (): string => {
        let errorMessage = "";
        const order = this.state.order;
        // console.log(order)
        if(order.status === "Ready for pickup" && order.packaged === "Yes" && order.completed === "Yes") {
            if(order.tare_Weight && order.gross_Weight && order.trailer_Number && order.truck_Number){
                const isMissingActualQuantity = order.product_Order.some((obj: IProductOrder) => !obj.actual_Quantity || obj.actual_Quantity === 0);
                // console.log(isMissingActualQuantity)
                if(isMissingActualQuantity) {
                    errorMessage = "Completed orders must have an actual quantity!"
                }
                else if(!(order.total_Tonnage > 0)) {
                    errorMessage = "Total tonnage must be greater than 0!";
                }
            } 
            else {
                errorMessage = "Gross, tare, truck and trailer numbers must be entered if the order is completed!";
            }
        }
        else {
            if(Number(order.gross_Weight) > 0 && Number(order.tare_Weight) > 0){
                if(!(order.total_Tonnage > 0)){
                    errorMessage = "Total tonnage must be > 0 if gross and tare weight are entered!";
                }
            }
        }
        return errorMessage
    }

    private onSave = (): void => {
        const order = this.state.order;
        const validateFormError = this.validateForm();
        const productError = this.validateProductsTable();
        // if (order.product_Order[0].product === "") {
        //     this.setState({ productError: true });
        // } 
        if (validateFormError) {
            this.setState({ validationError: validateFormError });
        } 
        else if (productError) {
            this.setState({ validationError: productError });
        } 
        else {
            if (hasPayload(this.props.getToken)) {
                const token = this.props.getToken.payload.response.token;

                if (order.id === 0) {
                    this.props.addOrderRequest({
                        token,
                        request: {
                            ...order,
                            created: new Date().toISOString(),
                            modified: new Date().toISOString()
                        }
                    });
                } else {
                    const proList: IUpdateProductOrder[] = [];

                    order.product_Order.forEach((x) => {
                        proList.push({
                            ID: x.id,
                            Quantity: x.quantity,
                            Size: x.size,
                            Per: x.per,
                            Product: x.product,
                            Code: x.code,
                            CW: x.cW,
                            Double_CW: x.double_CW,
                            Actual_Quantity: x.actual_Quantity
                        });
                    });
                    
                    const obj: IUpdateOrder = {
                        ID: order.id,
                        Customer_ID: order.customer_ID,
                        Pickupdate: order.pickupdate,
                        Pickuphour: order.pickuphour,
                        Pickupminute: order.pickupminute,
                        Sitecontact: order.sitecontact,
                        Sitecontact_Phone: order.sitecontact_Phone,
                        PO_Number: order.pO_Number,
                        Emailuser: order.emailuser,
                        Emailtransport: order.emailtransport,
                        Transport_Company: order.transport_Company,
                        Truck_Required: order.truck_Required,
                        Deliverydate: order.deliverydate,
                        Deliveryhour: order.deliveryhour,
                        Deliveryminute: order.deliveryminute,
                        Location: order.location,
                        Comments: order.comments,
                        Packaged: order.packaged,
                        Completed: order.completed,
                        Cancelled: order.cancelled,
                        Truck_Number: order.truck_Number ? order.truck_Number.toString() : "",
                        Trailer_Number: order.trailer_Number ? order.trailer_Number.toString() : "",
                        Tare_Weight: order.tare_Weight,
                        Gross_Weight: order.gross_Weight,
                        Modified_By: userName,
                        Product_Order: proList,
                        Total_Tonnage: order.total_Tonnage,
                        Status: order.status,
                        Order_Type: order.order_Type,
                        Transport_Company_Notes: order.transport_Company_Notes,
                        Delivery_Datetime: order.delivery_Datetime,
                        Pickup_Datetime: order.pickup_Datetime,
                        Zone: order.zone,
                        Hired_Truck_Rates: order.hired_Truck_Rates,
                        Truck_Code: order.truck_Code,
                        Time_In: order.time_In,
                        Time_Out: order.time_Out,
                        Customer_Job_Number: order.customer_Job_Number,
                        Order_Contact: order.order_Contact
                    };

                    this.props.updateOrderRequest({
                        token,
                        request: obj
                    });
                };
            }
        }
    };

    private handleCancel = (): void => {
        // this.props.history.push(ROUTE.SIMOS.SIL_Ed_Orders.INDEX);
        callRouteWithQueryString({
            route: this.props,
            search: { page: "sil_ed" },
            pathName: ROUTE.SIMOS.SIL_Ed_Orders.INDEX
        });
    };

    private handleClose = (): void => {
        const confirm = window.confirm("Any unsaved changes will be lost. Are you ok to leave?");

        if(confirm) {
            callRouteWithQueryString({
                route: this.props,
                search: { page: "sil_ed" },
                pathName: ROUTE.SIMOS.SIL_Ed_Orders.INDEX
            });
        }
    };

    private closeProductVersionHistoryPopUp = (): void => {
        this.setState({
            productVersionHistoryPopup: false,
        });
    };

    private handleProductVersionHistory = (product_ID: number): void => {
        if (hasPayload(this.props.getToken)) {
            const token = this.props.getToken.payload.response.token;
            this.props.getSimosOrderProductVersionHistoryRequest({
                token,
                id: product_ID
            });
    
            this.setState({
                productVersionHistoryPopup: true
            });
        }
    };

    private handleTransportPopup = (): void => {
        this.setState({ addTransport: !this.state.addTransport });
    };

    private handleAddCustomer = (): void => {
        this.setState({ addCustomer: !this.state.addCustomer });
    };

    private handleAddAddress = (): void => {
        this.setState({ addAddress: !this.state.addAddress });
    };

    private handleAddSalesOrder = (): void => {
        this.setState({ addSalesOrder: !this.state.addSalesOrder });
    };

    private handleSaveSalesOrderNumber = (data:any): void => {
        // console.log(data);
        const order = this.state.order;
        if(order.customer_ID > 0) {
            if (hasPayload(this.props.getToken)) {
                const token = this.props.getToken.payload.response.token;
                this.props.addSalesOrderNumberRequest({
                    token,
                    request: {
                        Sales_Order_Number: data.sales_Order_Number,
                        Customer_ID: order.customer_ID,
                        Created_By: userName
                    }
                });
            }
        }

    }

    private handleAddContact = (): void => {
        this.setState({ addContact: !this.state.addContact });
    };

    private onTableChange = (name: string, value: string, index: number): void => {
        const iS = [...this.state.order.product_Order];
        let product = { ...iS[index] };
        product = { ...product, [name]: value };

        iS[index] = product;

        this.setState({
            ...this.state,
            order: {
                ...this.state.order,
                product_Order: iS
            }
        });
    };

    private onTableProductDelete = (index: number): void => {
        const selection = window.confirm(deleteText);
        
        if(selection) {
            const iS = [...this.state.order.product_Order];
            iS.splice(index, 1);
            this.setState({
                ...this.state,
                order: {
                    ...this.state.order,
                    product_Order: iS
                }
            });
        }
    };

    private onTableDropDownChange = (event: unknown, value: any, index: number, name: string, setName: string): void => {
        const iS = [...this.state.order.product_Order];
        let product = { ...iS[index] };
        product = { ...product, [name]: value[setName] };

        if(this.state.order.location === "SIL ED Warehouse") {
            if(name === "size" && product.product) {
                // console.log(value);
                if(value) {
                    product = { ...product, code: value.code };
                }
                else {
                    product = { ...product, code: "" };
                }
            }
            if(name === "product") {
                product = { ...product, size: "", code: "" };
            }
        }
        else if(this.state.order.location === "Bulk") {
            if(name === "product") {
                // console.log(value);
                if(value) {
                    product = { ...product, size: "", code: value.code, quantity: 0 };
                }
                else {
                    product = { ...product, size: "", code: "", quantity: 0 };
                }
            }
            if(name === "size") {
                if(value) {
                    product = { ...product, quantity: value.size };
                }
                else {
                    product = { ...product, quantity: 0 };
                }
            }
        }

        iS[index] = product;

        this.setState({
            ...this.state,
            order: {
                ...this.state.order,
                product_Order: iS
            }
        });
    };

    private onProductCheckBox = (checked: boolean, index: number, name: string): void => {
        const iS = [...this.state.order.product_Order];
        let product = { ...iS[index] };
        product = { ...product, [name]: checked ? "Yes" : "No" };

        iS[index] = product;

        this.setState({
            ...this.state,
            order: {
                ...this.state.order,
                product_Order: iS
            }
        });
    };

    private onProductAdd = (): void => {
        const products = [...this.state.order.product_Order];

        products.push({
            id: 0,
            quantity: 0,
            size: "",
            per: "",
            product: "",
            code: "",
            cW: "",
            double_CW: "",
            actual_Quantity: 0
        });

        this.setState({
            ...this.state,
            order: {
                ...this.state.order,
                product_Order: products
            }
        });
    };

    private onProductClick = (event: unknown, view: string): void => {
        this.setState({
            ...this.state,
            order: {
                ...this.state.order,
                location: view,
                product_Order: [{
                    id: 0,
                    quantity: 0,
                    size: "",
                    per: "",
                    product: "",
                    code: "",
                    cW: "",
                    double_CW: "",
                    actual_Quantity: 0
                }]
            }
        });
    };

    private onEmailUser = (value: boolean): void => {
        this.setState({
            // collect: !this.state.collect,
            order: {
                ...this.state.order,
                emailuser: value ? yesOrNoOptions[0].name : yesOrNoOptions[1].name
            }
        });
    };

    private onEmailTransport = (value: boolean): void => {
        this.setState({
            // collect: !this.state.collect,
            order: {
                ...this.state.order,
                emailtransport: value ? yesOrNoOptions[0].name : yesOrNoOptions[1].name
            }
        });
    };

    private onCollect = (value: boolean): void => {
        let errors = this.state.errors;
        if(value) {
            delete errors["transport_Company"];
            delete errors["truck_Required"];
        } 
        else {
            errors = this.errorChecker("transport_Company", "", errors, true);
            errors = this.errorChecker("truck_Required", "", errors, true);
        }
        this.setState({
            collect: value,
            order: {
                ...this.state.order,
                order_Type: value ? "collect" : "prepaid",
                truck_Code: "",
                transport_Company: value ? "Customer Pickup" : "",
                truck_Required: value ? truckSizeList[12].name : "",
                zone: value ? "00" : "",
                transport_Company_Notes: "",
                emailtransport: "No"
            },
            errors
        });
    };

    private onChange = (name: string, value: string): void => {
        let iS = this.state.order;
        let errors = this.state.errors;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        if (name === "transport_Company") {
            if (value === "Yellowhead Trucking") {
                errors = this.errorChecker("truck_Code", "", errors, true);
            } else {
                if (errors["truck_Code"])
                    delete errors["truck_Code"];
            }
        };

        if (name === "truck_Code") {
            if(iS.transport_Company === "Yellowhead Trucking" && value.length === 0) {
                errors = this.errorChecker("truck_Code", "", errors, true);
            } 
            else {
                delete errors["truck_Code"];
            }
        }


        if (name === "tare_Weight") {
            iS.total_Tonnage = (Number(iS.gross_Weight.toLowerCase()) - Number(value.toLowerCase())) / 1000
        } else if (name === "gross_Weight") {
            iS.total_Tonnage = (Number(value.toLowerCase()) - Number(iS.tare_Weight.toLowerCase())) / 1000
        }

        this.setState({
            ...this.state,
            errors,
            order: {
                ...iS,
                [name]: value
            }
        });
    };

    private handleDate = (name: string, date: string): void => {
        let iS = this.state.order;
        let errors = this.state.errors;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, date, errors, true);

        if (name === "delivery_Datetime") {
            iS.deliverydate = getDateForOrder(date);
            iS.deliveryhour = getDateHourForOrder(date);
            iS.deliveryminute = getDateMinutesForOrder(date);
        } else if (name === "pickup_Datetime") {
            iS.pickupdate = getDateForOrder(date);
            iS.pickuphour = getDateHourForOrder(date);
            iS.pickupminute = getDateMinutesForOrder(date);
        };

        this.setState({
            ...this.state,
            order: {
                ...iS,
                [name]: date
            },
            errors
        });
    };

    private handleDropDownChange = async(event: unknown, value: any, name?: string): Promise<void> => {
        if (name) {
            let iS = { ...this.state.order };
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value, errors, true);

            if (name === "customer") {
                iS.customer_ID = value.id;
                iS.sales_Order_Number = "";
                iS.address = "";
                errors = this.errorChecker("address", iS.address, errors, true);
                iS.order_Contact = {
                    Email :"",
                    Contact: "",
                    Cell: "" ,
                    Phone: "",
                    Customer_ID: 0,
                }
                errors = this.errorChecker("selectedContact", "", errors, true);
                if(value.id && hasPayload(this.props.getToken)) {
                    await this.props.getSalesOrderNumbersByCustomerIDRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            Customer_ID: Number(value.id)
                        }
                    });
                }
            }

            this.setState({
                ...this.state,
                order: {
                    ...iS,
                    [name]: value !== "" ? value[name] : ""
                },
                errors
            });
        }
    };

    
    private handleDDWithName = (event: unknown, value: any, name?: string, getLabelName?: string): void => {
        let errors = this.state.errors;
        if (name && getLabelName) {
            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value, errors, true);

            if(name === "transport_Company") {
                this.state.order.truck_Code = ""
                if (value[getLabelName] === "Yellowhead Trucking") {
                    errors = this.errorChecker("truck_Code", "", errors, true);
                } else {
                    if (errors["truck_Code"])
                        delete errors["truck_Code"];
                }
            }

            this.setState({
                ...this.state,
                order: {
                    ...this.state.order,
                    [name]: value !== "" ? value[getLabelName] : ""
                },
                errors
            });
        }
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken)) {
            const query = queryString.parse(this.props.location.search);
            const id = query.id ?? "0";

            if (this.props.getToken.payload.response.simosAccess.access !== NotApplicable) {

                if (Number(id) > 0 && isNotLoaded(this.props.getProducts)) {
                    this.props.getProductsRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            Order_ID: Number(id)
                        }
                    });
                }

                if (isNotLoaded(this.props.getSILEdOrder))
                    this.getOrders();

                if (isNotLoaded(this.props.getSimosCustomersList))
                    this.getCustomers();

                if (isNotLoaded(this.props.getSimosTransportCompanyList))
                    this.getCompanies();

                if (isNotLoaded(this.props.getSimosProductsList))
                    this.getProducts();

                if (isNotLoaded(this.props.getSimosCustomerContacts))
                    this.props.getSimosCustomerContactsRequest({
                        token: this.props.getToken.payload.response.token
                    });

            } else {
                this.props.history.push({
                    pathname: ROUTE.SIMOS.INDEX,
                });
            };

            if ((id !== "0") && (hasPayload(this.props.getSILEdOrder) && hasPayload(this.props.getProducts)) && hasPayload(this.props.getOrderContact)) {
                const order = this.props.getSILEdOrder.payload.response;
                const custContact = this.props.getOrderContact.payload.response;

                const products = this.props.getProducts.payload.response;
                const newList: IProductOrder[] = [];

                products.forEach((x) => {
                    newList.push({
                        id: x.id,
                        quantity: x.quantity,
                        size: x.size,
                        per: x.per,
                        product: x.product,
                        code: x.code,
                        cW: x.cw,
                        double_CW: x.double_CW,
                        actual_Quantity: x.actual_Quantity,
                        batch: x.batch
                    });
                });

                if (order) {
                    this.setState({
                        ...this.state,
                        errors: {},
                        collect: order.order_Type === "collect" ? true : false,
                        order: {
                            ...order,
                            product_Order: newList,
                            order_Contact: {
                                Email: custContact[0].email,
                                Contact: custContact[0].contact,
                                Cell: custContact[0].cell,
                                Phone: custContact[0].phone,
                                Customer_ID: custContact[0].customer_ID,
                                // Selected: "Yes"
                            }
                        }
                    });

                }
            };
        };
    }

    private getOrders = (): void => {
        if (hasPayload(this.props.getToken)) {

            const token = this.props.getToken.payload.response.token;
            const query = queryString.parse(this.props.location.search);
            const id = query.id ?? "0";

            if (+id > 0) {
                this.props.getProductsRequest({
                    token,
                    request: {
                        Order_ID: +id
                    }
                });

                this.props.getOrderContactRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        order_id: +id,
                        // customer_id: 66
                    }
                });

                this.props.getSILEdOrderRequest({
                    token,
                    request: {
                        id: +id
                    }
                });
            }
        };
    };
    private getCustomers = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSimosCustomersRequest({
                token: this.props.getToken.payload.response.token,
            });
        };
    };

    private getCompanies = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSimosTransportCompanyRequest({
                token: this.props.getToken.payload.response.token,
            });
        };
    };

    private getProducts = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSimosProductsListRequest({
                token: this.props.getToken.payload.response.token,
            });
        };
    };

}

const mapStateToProps = (state: IStore): ISILEdOrderStoreProps => ({
    getToken: getTokenStatus(state),
    getSILEdOrder: getOrderByIdStatus(state),
    getSimosProductsList: getSimosProducts(state),
    getSimosCustomersList: getSimosCustomers(state),
    getSimosCustomerContacts: getSimosCustomerContacts(state),
    getSimosTransportCompanyList: getSimosTransportCompanies(state),
    getProducts: getProductsById(state),
    addOrder: addOrderStatus(state),
    updateOrder: updateOrderStatus(state),
    getOrderContact: getOrderContactStatus(state),
    getSimosTrucks: getSimosTrucksStatus(state),
    getSimosOrderProductVersionHistory: getSIMOSOrderProductVersionHistory(state),
    getSalesOrderNumbersByCustomerID: getSalesOrderNumbersByCustomerID(state),
    addSalesOrderNumber: addSalesOrderNumberStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISILEdOrderDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSILEdOrderRequest: (data: IGetOrderByIdRequest) => dispatch(getOrderByIdLoadAction(data)),
    getSimosCustomersRequest: (data: ISimosCustomersRequest) => dispatch(getSimosCustomersLoadAction(data)),
    getSimosProductsListRequest: (data: ISimosProductsRequest) => dispatch(getSimosProductsLoadAction(data)),
    getSimosCustomerContactsRequest: (data: ISimosCustomerContactsRequest) => dispatch(getSimosCustomerContactsLoadAction(data)),
    getSimosTransportCompanyRequest: (data: ISimosTransportCompaniesRequest) => dispatch(getSimosTransportCompaniesLoadAction(data)),
    getProductsRequest: (data: IGetProductsByIdRequest) => dispatch(getProductsByIdLoadAction(data)),
    addOrderRequest: (data: IAddOrderRequest) => dispatch(addOrderLoadAction(data)),
    updateOrderRequest: (data: IUpdateOrderRequest) => dispatch(updateOrderLoadAction(data)),
    getOrderContactRequest: (data: IGetOrderContactRequest) => dispatch(getOrderContactLoadAction(data)),
    getSimosTrucksRequest: (data: IGetSimosTrucksRequest) => dispatch(getSimosTrucksLoadAction(data)),
    getSimosOrderProductVersionHistoryRequest: (data: IGetSIMOSOrderProductVersionHistoryRequest) => dispatch(getSIMOSOrderProductVersionHistoryLoadAction(data)),
    getSalesOrderNumbersByCustomerIDRequest: (data: IGetSalesOrderNumbersByCustomerIDRequest) => dispatch(getSalesOrderNumbersByCustomerIDLoadAction(data)),
    addSalesOrderNumberRequest: (data: IAddSalesOrderNumberRequest) => dispatch(addSalesOrderNumberLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SILEdOrder);

export const getDateForOrder = (val: string): string => {
    const today = new Date(val);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    if(year && month && day) {
        return `${year}-${month}-${day}`;
    }
    else {
        return ""
    }
};

export const getDateHourForOrder = (val: string): string => {
    const today = new Date(val);
    let hours = today.getHours();
    // const minutes = String(today.getMinutes()).padStart(2, '0');
    if(hours) {
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return `${hours} ${ampm}`;
    }
    else {
        return ""
    }
};

export const getDateMinutesForOrder = (val: string): string => {
    const today = new Date(val);
    const minutes = String(today.getMinutes()).padStart(2, '0');
    return minutes;
};